<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <div class="box-card" shadow="never">
                <!-- 按钮 -->
                <el-row style="height:40px;">
                     <el-button type="primary" @click="addPurchase(0,1)" class="buttons">组合商品</el-button>
                     <el-button type="primary" @click="addGroupUnit(0,2)" class="buttons">辅佐单位</el-button>
                     <el-button type="primary" @click="addCampaign(0,3)" class="buttons">活动创建</el-button>
                </el-row>
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="数据类型">
                        <el-select v-model="req.type" @change="getList" placeholder="请选择">
                            <el-option
                            v-for="item in typeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="关键词">
                        <el-input
                            placeholder="请输入关键词搜索"
                            prefix-icon="el-icon-search"
                            v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">搜索</el-button>
                        <!-- <el-button @click="onReset">重置</el-button> -->
                    </el-form-item>
                </el-form>

                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width:96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="类型"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.type_name }}</template>
                    </el-table-column>

                    <el-table-column label="名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.name }}</template>
                    </el-table-column>
                    <el-table-column label="创建时间"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.create_time }}</template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" v-if="scope.row.type == '1'" size="small" @click="addPurchase(scope.row.id)" >
                                编辑
                            </el-button>
                            <!-- <el-button type="text" v-if="scope.row.type == '2'" size="small" @click="addGroupUnit(scope.row.id)" >
                                编辑
                            </el-button> -->
                            <el-button type="text" v-if="scope.row.type == '3'" size="small" @click="addCampaign(scope.row.id)" >
                                编辑
                            </el-button>
                            <!-- 详情 -->
                            <el-button type="text" v-if="scope.row.type == '1'" size="small" @click="groupDetail(scope.row.id)" >
                                详情
                            </el-button>
                            <el-button type="text" v-if="scope.row.type == '2'" size="small" @click="addGroupUnit(scope.row.id)" >
                                详情
                            </el-button>
                            <el-button type="text" v-if="scope.row.type == '3'" size="small" @click="campaignDetail(scope.row.id)" >
                                详情
                            </el-button>

                            <el-button type="text" size="small"  @click="remove(scope.row.id)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <!-- 页码 -->
        <div class="pageDiv" style="">
            <el-pagination
                style="margin:10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="添加商品"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        :close-on-click-modal="false"
        width="80%"
        center>
            <createShare :isCopy="isCopy" :id="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>

        <el-dialog
        :modal="false"
        title="大图"
        :visible.sync="showPicFlag"
        width="600px">
        <div style="max-width:800px;max-height:500px;">
            <img style="width:100%;" :src="showSrc" alt="">
        </div>
        </el-dialog>

    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            baseUrl: tools.baseURL(),
            //上传图片列表地址
            uploadImages: [

            ],
            classify_id:'',
            createShareFlag:false,
            shareListFlag:false,
            showPicFlag:false,
            showSrc:'',
            currentId:0,
            tableData:[],
            isCopy:0,
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            typeList:[{
                    value:'',
                    label:'所有'
                },{
                    value:'1',
                    label:'组合商品'
                },{
                    value:'2',
                    label:'辅佐单位'                    
                },{
                    value:'3',
                    label:'活动'                    
                }
            ],
            req:{
                limit:30,
                page:1,
                keywords:'',
                type:''
            },
            source:{},
        }
    },
    components:{
        createShare,shareListFlag
    },
    destroyed(){
        this.isCopy = 0;
    },
    methods:{
        onSubmit(){
            this.getList();
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            apis.getGoodsGroupsList(this.req).then((res) => {
                this.tableData = res.data.data;
                this.tableData.forEach(item=>{

                })
                this.total = res.data.total;
            }).catch((err) => {
                tools.err(err,this);
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        showPic(src){
            this.showPicFlag = true;
            this.showSrc = src;
        },
        remove(id, key) {
            this.$confirm('确定删除此记录?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.deleteGoodsGroups({ id: id}).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id) {
            this.$router.push({
                path: '/goods/add_groups?id=' + id
            });
        },
        copy(id) {
            this.$router.push({
                path: '/goods/add?id=' + id,
                query:{
                    is_copy:1
                }
            });
        },
        detail(id) {
            this.$router.push({
                path: '/goods/add_groups?id=' + id
            });
        },
        // 商品组合详情
        groupDetail(id){
            this.$router.push({
                path: '/goods/add_groups?action=detail&type=1&id=' + id
            });
        },
        // 活动详情
        campaignDetail(id){
            this.$router.push({
                path: '/goods/add_campaign?action=detail&type=3&id=' + id
            });
        },

        //新增组合
        addPurchase(id = 0,type = 1) {
            this.$router.push({
                path: '/goods/add_groups?type=1&id=' + id
            });
        },
        // 新增辅助单位
        addGroupUnit(id = 0,type = 2) {
            this.$router.push({
                path: '/goods/add_unit?type=2&id=' + id
            });
        },
        // 新增活动
        addCampaign(id = 0,type = 3) {
            this.$router.push({
                path: '/goods/add_campaign?type=3&id=' + id
            });
        },


    },
    created(){
        //获取各种数据源
        apis.goodsSource().then(res => {
            if (tools.msg(res, this)) {
                this.source = res.data;
            }
        }).catch(err => {
            tools.err(err, this);
        });
    },
    mounted(){

        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>